body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sui-layout-body__inner {
    max-width: fit-content!important;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 0 24px;
}

.sui-layout-main-body {
    display: flex;
}

.sui-layout-main {
    width: auto !important;
    padding: 32px 0 32px 0 !important;
}

.custom-edit-button {
    padding: 2px
}

.react-tagsinput-tag {
    background-color: whitesmoke !important;
    color: black;
}
